import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';
import { RouteObject } from 'react-router';
import Cookies from 'cookies-js';
import SidebarLayout from 'src/layouts/SidebarLayout';
import BaseLayout from 'src/layouts/BaseLayout';

import SuspenseLoader from 'src/components/SuspenseLoader';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

// Pages

const Overview = Loader(lazy(() => import('src/content/overview')));

// Dashboards

const Crypto = Loader(lazy(() => import('src/content/dashboards/Crypto')));

// Applications
const Login = Loader(lazy(() => import('src/content/authentication/SignIn')));
const Signup = Loader(lazy(() => import('src/content/authentication/Signup')));
const ForgetPassword = Loader(
  lazy(() => import('src/content/authentication/ForgetPassword'))
);
// const Messenger = Loader(
//   lazy(() => import('src/content/applications/Messenger'))
// );
const Employees = Loader(
  lazy(() => import('src/content/applications/Employees'))
);
const EmployeesProfile = Loader(
  lazy(() => import('src/content/applications/Employees/EmployeeProfile'))
);
const Transactions = Loader(
  lazy(() => import('src/content/applications/Transactions'))
);
const UserProfile = Loader(
  lazy(() => import('src/content/applications/Users/profile'))
);
const UserSettings = Loader(
  lazy(() => import('src/content/applications/Users/settings'))
);

const Stores = Loader(lazy(() => import('src/content/applications/Stores')));
// Components

const Buttons = Loader(
  lazy(() => import('src/content/pages/Components/Buttons'))
);
const Modals = Loader(
  lazy(() => import('src/content/pages/Components/Modals'))
);
const Accordions = Loader(
  lazy(() => import('src/content/pages/Components/Accordions'))
);
const Tabs = Loader(lazy(() => import('src/content/pages/Components/Tabs')));
const Badges = Loader(
  lazy(() => import('src/content/pages/Components/Badges'))
);
const Tooltips = Loader(
  lazy(() => import('src/content/pages/Components/Tooltips'))
);
const Avatars = Loader(
  lazy(() => import('src/content/pages/Components/Avatars'))
);
const Cards = Loader(lazy(() => import('src/content/pages/Components/Cards')));
const Forms = Loader(lazy(() => import('src/content/pages/Components/Forms')));

// Status

const Status404 = Loader(
  lazy(() => import('src/content/pages/Status/Status404'))
);
const Status500 = Loader(
  lazy(() => import('src/content/pages/Status/Status500'))
);
const StatusComingSoon = Loader(
  lazy(() => import('src/content/pages/Status/ComingSoon'))
);
const StatusMaintenance = Loader(
  lazy(() => import('src/content/pages/Status/Maintenance'))
);
const isAuth = Cookies.get('restro-token') ? true : false;
const routes: RouteObject[] = [
  {
    path: '',
    element: isAuth ? <Navigate to="/dashboards/crypto" /> : <BaseLayout />,
    children: [
      {
        path: '/',
        element: <Login />
      },
      {
        path: '/sign-up',
        element: <Signup />
      },
      {
        path: '/forget-password',
        element: <ForgetPassword />
      },
      {
        path: 'overview',
        element: <Navigate to="/" replace />
      },
      {
        path: 'status',
        children: [
          {
            path: '',
            element: <Navigate to="404" replace />
          },
          {
            path: '404',
            element: <Status404 />
          },
          {
            path: '500',
            element: <Status500 />
          },
          {
            path: 'maintenance',
            element: <StatusMaintenance />
          },
          {
            path: 'coming-soon',
            element: <StatusComingSoon />
          }
        ]
      },
      {
        path: '*',
        element: <Status404 />
      }
    ]
  },
  {
    path: 'dashboards',
    element: isAuth ? <SidebarLayout /> : <Navigate to="/" />,
    children: [
      {
        path: '',
        element: isAuth ? <Navigate to="crypto" /> : <Navigate to="/" />
      },
      {
        path: 'crypto',
        element: isAuth ? <Crypto /> : <Navigate to="/" />
      }
    ]
  },
  {
    path: 'management',
    element: isAuth ? <SidebarLayout /> : <Navigate to="/" />,
    children: [
      {
        path: '',
        element: isAuth ? (
          <Navigate to="transactions" replace />
        ) : (
          <Navigate to="/" />
        )
      },
      {
        path: 'transactions',
        element: isAuth ? <Transactions /> : <Navigate to="/" />
      },
      {
        path: 'employees/*',
        element: isAuth ? <Employees /> : <Navigate to="/" />
      },
      {
        path: 'employees/:id',
        element: isAuth ? <EmployeesProfile /> : <Navigate to="/" />
      },
      {
        path: 'stores',
        element: isAuth ? <Stores /> : <Navigate to="/" />
      },
      {
        path: 'profile',
        children: [
          {
            path: '',
            element: <Navigate to="details" replace />
          },
          {
            path: 'details',
            element: <UserProfile />
          },
          {
            path: 'settings',
            element: <UserSettings />
          }
        ]
      }
    ]
  },
  {
    path: '/components',
    element: <SidebarLayout />,
    children: [
      {
        path: '',
        element: <Navigate to="buttons" replace />
      },
      {
        path: 'buttons',
        element: <Buttons />
      },
      {
        path: 'modals',
        element: <Modals />
      },
      {
        path: 'accordions',
        element: <Accordions />
      },
      {
        path: 'tabs',
        element: <Tabs />
      },
      {
        path: 'badges',
        element: <Badges />
      },
      {
        path: 'tooltips',
        element: <Tooltips />
      },
      {
        path: 'avatars',
        element: <Avatars />
      },
      {
        path: 'cards',
        element: <Cards />
      },
      {
        path: 'forms',
        element: <Forms />
      }
    ]
  }
];

export default routes;
