import { createContext, ReactNode, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Cookies from 'js-cookie';

type AuthType = {
  token: string;
  setToken: React.Dispatch<React.SetStateAction<string>>;
  isLoading: boolean;
  err: string;
  email: string;
  setEmail: React.Dispatch<React.SetStateAction<string>>;
  password: string;
  setPassword: React.Dispatch<React.SetStateAction<string>>;
  organizationId: number | undefined;
  setOrganizationId: React.Dispatch<React.SetStateAction<number | undefined>>;
  loginHandler: () => Promise<void>;
  logoutHandler: () => void;
};

const AuthContext = createContext<AuthType>({
  token: '',
  setToken: () => {},
  isLoading: false,
  err: '',
  email: '',
  setEmail: () => {},
  password: '',
  setPassword: () => {},
  organizationId: undefined,
  setOrganizationId: () => {},
  loginHandler: async () => {},
  logoutHandler: () => {}
});

export default AuthContext;

type AuthProviderProps = {
  children: ReactNode;
};

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const navigate = useNavigate();
  const [token, setToken] = useState<string>(Cookies.get('restro-token'));
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [err, setErr] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('Mm@12345');
  const [organizationId, setOrganizationId] = useState<number>();
  //sign in
  const signin = async (): Promise<void> => {
    setIsLoading(true);
    setErr('');
    try {
      const response = await axios.post(
        `${window.domain}/auth/signinRestaurants`,
        {
          email,
          organization_id: organizationId,
          password
        },
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );
      const results = await response.data;
      if (results && results.status === 200 && results.accessToken) {
        Cookies.set('restro-token', results.accessToken);
        setToken(results.accessToken);
        // navigate('/dashboards/crypto');
        window.location.reload();
      }
      if (
        (results.status === 403 ||
          results.status === 401 ||
          results.status === 400) &&
        (results.error_en || results.messageEn)
      ) {
        throw new Error(results.error_en || results.messageEn);
      }
      console.log(results);
      setIsLoading(false);
    } catch (err) {
      setErr(err.message);
      setIsLoading(false);
    }
  };

  const logoutHandler = (): void => {
    Cookies.remove('restro-token');
    setToken('');
    window.location.reload();
  };

  const authValue = {
    token,
    setToken,
    isLoading,
    err,
    email,
    setEmail,
    password,
    setPassword,
    organizationId,
    setOrganizationId,
    loginHandler: signin,
    logoutHandler
  };

  return (
    <AuthContext.Provider value={authValue}>{children}</AuthContext.Provider>
  );
};
