import { FC, ReactNode, createContext, useState } from 'react';

interface Notification {
  notification: null | Record<string, string>;
  showNotification: (obj: Record<string, string>) => void;
  hideNotification: () => void;
}

const NotificationContext = createContext<Notification>({
  notification: null,
  showNotification: (obj) => {},
  hideNotification: () => {}
});

export default NotificationContext;

interface ChildrenProp {
  children: ReactNode;
}

export const NotificationProvider: FC = ({ children }: ChildrenProp) => {
  const [notification, setNotification] = useState<null | Record<
    string,
    string
  >>(null);
  const showNotificationHandler = (obj: Record<string, string>) => {
    setNotification({
      status: obj.status,
      message: obj.message,
      header: obj.header
    });
  };

  const hideNotificationHandler = () => {
    setNotification(null);
  };

  const value = {
    notification,
    showNotification: showNotificationHandler,
    hideNotification: hideNotificationHandler
  };

  return (
    <NotificationContext.Provider value={value}>
      {children}
    </NotificationContext.Provider>
  );
};
